<template>
    <Header />
    <div class="container container-width">
        <h2>No Internet</h2>
        <p class="lead text-start">
            Your device is not connected to Internet
        </p>
        <p class="text-muted">
            Please check your internet connection.
        </p>
    </div>
</template>

<script>
import Header from '../../shared/navigation/Header.vue';
export default {
    name: 'NoInternet',
    components: {
        Header
    },
    mounted() {
        this.$router.replace({ path: this.$store.getters.landingPage });
    }
};
</script>

<style>
.container{
    margin-top:6rem;
}
</style>
